<template>
  <b-row>
    <b-col lg="12">
      <b-card>
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <select-language :selected-language="addNewBlog" />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Title"
              >
                <b-form-group
                  label="Title"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="addNewBlog.title"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Title"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Excerpt"
              >
                <b-form-group
                  label="Excerpt"
                  label-for="Excerpt"
                >
                  <b-form-textarea
                    id="Excerpt"
                    v-model="addNewBlog.exerpt"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Excerpt"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Descripption"
                label-for="blog-content"
                class="mb-2"
              >
                <quill-editor
                  id="blog-content"
                  v-model="addNewBlog.description"
                  :options="snowOption"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="cat"
              >
                <b-form-group
                  label="Categories"
                  label-cols="2"
                  label-for="cat"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="addNewBlog.categories"
                    label="name"
                    multiple
                    :options="categoriesList"
                    :reduce="cat => cat.id"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Featured Image
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      :src="preview"
                      height="200"
                      width="200"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <div class="d-inline-block">
                      <!-- :state="Boolean(featuredImage.image)" -->
                      <b-form-file
                        v-model="featuredImage.image"
                        accept=".jpg, .png"
                        placeholder="Choose file"
                        @change="onChange"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-if="!loader"
                variant="primary"
                class="mr-1"
                @click="addBlog"
              >
                Add
              </b-button>
              <b-button
                v-if="loader"
                variant="primary"
                disabled
                class="mr-1"
              >
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  computed, onUnmounted,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { quillEditor } from 'vue-quill-editor'
import { required } from '@validations'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import events from '../blog'
import eventsModule from '../BlogModule'
import handleFormData from '@/common/compositions/handleFormData'
import selectLanguage from '@/common/components/SelectLanguage.vue'

export default {
  components: {
    selectLanguage,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      addNewBlog: {
        title: '',
        exerpt: '',
        image: '',
        description: '',
        categories: [],
        language_id: '',
      },
      featuredImage: {
        image: null,
        url: null,
      },
      lists: [],
      preview: null,
      image: null,
      validationErrors: {},
      required,
      loader: false,
    }
  },
  mounted() {
  },
  setup() {
    const EVENTS_DATA_MODULE = 'eventsModule'

    // Register module
    if (!store.hasModule(EVENTS_DATA_MODULE)) store.registerModule(EVENTS_DATA_MODULE, eventsModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENTS_DATA_MODULE)) store.unregisterModule(EVENTS_DATA_MODULE)
    })
    const { formData, setFormData } = handleFormData()
    const {
      certificationList, addingProduct, eventsForm,
    } = events()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    // Get Categories List
    const fetchCategoriesList = () => store.dispatch('categoriesDetils/getBlogCategory')
    fetchCategoriesList()
    const categoriesList = computed(() => store.state.categoriesDetils.blogCategory)

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      certificationList,
      addingProduct,
      eventsForm,
      categoriesList,
      formData,
      setFormData,
    }
  },
  created() {
    this.getCategories()
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.addNewBlog.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    onChangeFile(e) {
      this.addNewBlog.file = e.target.files
      // eslint-disable-next-line no-restricted-syntax
    },
    addBlog() {
      this.$refs.infoRules.validate().then(success => {
        if (success) {
          this.loader = true
          this.setFormData(this.addNewBlog)
          axios.post('add/blog', this.formData).then(res => {
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Added Successfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$router.push('/blog')
            }
          }).catch(err => {
            if (err) {
              Object.assign(this.validationErrors, err.response.data.errors)
            }
          }).finally(() => {
            this.loader = false
          })
        }
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
